<template>
    <div class="margin-payment-detail">
        <!-- 项目信息 -->
        <project-info-panel :workflow-apply-record-id="workflowApplyRecordId" />

        <!-- 保证金专户信息 -->
        <special-account-info-panel class="has-margin-top" :data-source="specialBankAccountInfo" />

        <!-- 办理资料 -->
        <a-card class="has-margin-top">
            <template #title>
                <span style="font-size: 14px">办理资料</span>
            </template>
            <custom-file-upload-list ref="fileList" v-bind="fileUploadListProps" />
        </a-card>

        <custom-portal :to="portalTo">
            <a-button
                type="primary"
                :loading="confirming"
                @click.stop="handleSure"
            >
                确定
            </a-button>
        </custom-portal>
    </div>
</template>
<script>
import { Card as ACard, Button as AButton } from 'ant-design-vue'
import CustomPortal from '@components/CustomPortalVue/Portal'
import ProjectInfoPanel from '@weights/marginManagement/MarginPaymentDetail/ProjectInfoPanel'
import CustomFileUploadList from '@weights/CreatePage/components/CustomFileUploadList'

import SpecialAccountInfoPanel from '../../SpecialAccountInfoPanel'

import submitService from '@service/marginManagement/marginPaymentDetail/applyCashPaymentHandlingService'

export const PORTAL_KEY = 'CashPaymentHandlingApply'

export default {
    name: 'ModalContent',
    components: {
        ACard,
        AButton,
        CustomPortal,
        ProjectInfoPanel,
        CustomFileUploadList,
        SpecialAccountInfoPanel
    },
    props: {
        orderNo: String,
        specialBankAccountInfo: {
            type: Object,
            default: () => ({})
        },
        handlingInfoTpl: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            portalTo: PORTAL_KEY,

            confirming: false
        }
    },
    computed: {
        workflowApplyRecordId() {
            return this.$route.query.workflowApplyRecordId
        },
        fileUploadListProps() {
            return Object.freeze(this.handlingInfoTpl.props || {})
        }
    },
    methods: {
        async submitFormData(formData) {
            try {
                this.confirming = true

                const _formData = {
                    ...formData,
                    orderNo: this.orderNo
                }

                await submitService(_formData)

                this.$message.success('操作成功')
                this.$emit('on-ok')
            } catch (e) {
                this.$message.warning(e.message)
            } finally {
                this.confirming = false
            }
        },
        handleSure() {
            this.validateFileList().then(
                data => this.submitFormData(data),
                e => e
            )
        },
        validateFileList() {
            const ref = this.$refs.fileList
            return new Promise((resolve, reject) => {
                ref.vaildatorForm(valid => {
                    valid ? resolve(ref.getFormData()) : reject()
                })
            })
        }
    }
}
</script>
